@import '../../styles/index';

.base {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  hr {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $color-surrogate--40;
  }

  &:not(.full-width) {
    max-width: 500px;

    hr {
      width: 14.75rem;
    }
  }
}

.text {
  color: $color-surrogate--40;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 3.56px;
  line-height: 20px;
  text-align: center;
  margin: 0 0.875rem;
  text-transform: uppercase;
}

.children {
  display: flex;
  margin-inline: 2.875rem;
}
