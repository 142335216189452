@import '../../styles/index';

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 3px;
  background-color: rgba(199, 16, 92, 0.08);
  padding: 1rem 1rem 1.5rem;
  color: $color-surrogate--100;
  font-size: 1rem;
  line-height: 1.25rem;
  user-select: none;

  @include media-from(xs) {
    flex-direction: row;
    text-align: left;
    padding: 1.5rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  & i {
    margin-bottom: 0.5rem;
    min-width: 1.75rem;
    min-height: 1.75rem;

    @include media-from(xs) {
      margin-right: 1.5rem;
      margin-bottom: 0;
    }
  }
}
.text {
  max-width: 450px;

  & > a {
    color: $color-surrogate--100;
    text-decoration: underline !important;
  }
}
