@import '../../styles/index';

.base {
  font-size: $font-size-18;
  border-radius: $border-radius-default;
  width: 100%;
  background-color: $color-white--100;
  position: relative;
  box-sizing: border-box;
}

.shadow {
  box-shadow: $shadow-hoverable;
}
.centered {
  margin-left: auto;
  margin-right: auto;
}
.max-height {
  min-height: calc(100vh - 4rem);
}

.large {
  width: 75rem;
  max-width: 100%;
  padding: 2rem 1rem 1rem;

  @include media-from(xs) {
    padding: 2rem;
    max-width: calc(100% - 4rem);
    border-radius: $border-radius-default;
  }

  @include media-from(lg) {
    width: 1200px;
  }
}

.auto {
  width: auto;
}

.default {
  width: 55rem;
  max-width: 100%;
  padding: 2rem 1rem 1rem;

  @include media-from(sm) {
    padding: 4rem 6rem 3rem;
  }
}

.lmedium {
  width: 808px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
}

.medium {
  width: 47rem;
  max-width: 100%;
  padding: 3rem 1rem !important;

  @include media-from(sm) {
    padding: 5rem !important;
  }
}

.smedium {
  width: calc(640px + 64px);
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;

  @include media-from(sm) {
    padding: 2rem;
  }
}

.socialmedium {
  width: calc(40rem + 4rem);
}

.normal {
  width: 640px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;

  @include media-from(sm) {
    padding: 2rem;
  }
}

.small {
  width: 550px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
}

.job-apply {
  // its 640px (40rem) but it works ony with 64px more (4rem)
  width: 44rem;
  max-width: 100%;
  padding: 3rem 1rem !important;

  @include media-from(sm) {
    margin-top: unset;
    padding: 3rem !important;
  }
}

.alternative {
  border-radius: 6px;
  background-color: $color-white--100;
  position: relative;
  box-sizing: border-box;

  &.default {
    padding: 1.25rem;
  }
  &.shadow {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.12), 0 8px 48px rgba(0, 0, 0, 0.08);
  }
}

.eng-calorific-value-calculator {
  width: 960px;
  max-width: 100%;
  padding: 2.5rem 1rem;

  @include media-from(xs) {
    padding: 4rem 3rem;
    max-width: calc(100% - 4rem);
  }

  &.shadow {
    box-shadow: $shadow-eng-calorific-value-box;
  }
}
