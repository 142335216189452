@import '../../styles/index';

.base {
  position: fixed;
  top: 0;
  left: 0;
  max-height: 100%;
  overflow: auto;
  background-color: $color-black--10;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 100;
  height: 100%;
}

.container {
  margin: 0 auto;
  max-width: 720px;
  padding: 0 2rem;

  &:first-child {
    margin-top: auto;
    padding-top: 2rem;
  }

  &:last-child {
    margin-bottom: auto;
    padding-bottom: 2rem;
  }

  &:not(:last-child) {
    margin-bottom: 4rem;
  }
}

.headline {
  margin-bottom: 2rem;

  > h1 {
    margin-bottom: 1rem;
    display: block;

    @include media-from(xs) {
      font-size: $font-size-48 !important;
    }
  }
}

.note {
  font-weight: $font-weight-medium;
  text-align: center;
  font-size: $font-size-20;
  line-height: 1.5;
}

.tiles {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
}

.tile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: $font-weight-bold;
  font-size: $font-size-24;

  > img {
    margin-bottom: 1rem;
    height: 5rem;
    width: 5rem;
  }
}

.footnote {
  text-align: center;
  font-size: $font-size-16;
  line-height: 1.5;
  margin-bottom: 2rem;
}
