@import '../../styles/index';

.base {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  padding: 40px 28px 32px 28px;
  margin: 15px 0;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 0 27px 0 rgba(0, 0, 0, 0.12);
  flex-basis: 300px;
  max-width: 300px;
  height: 300px;
  transition: background-color $duration-default,
    transform $duration-default ease-in-out,
    box-shadow $duration-default ease-in-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: $shadow-button-hover;
  }

  .icon {
    width: 3.5rem;
    height: 3.5rem;
    display: block;
    margin: 0 auto;
  }
}

.description {
  width: 100%;
  line-height: 32px;
  margin-block-start: 0;
  margin-block-end: 0;
  user-select: none;
  text-align: center;
  font-size: $font-size-20;
}

.description-small {
  font-size: 1rem;
  font-weight: 400;
  color: $color-black--60;
  text-align: center;
  margin-bottom: 1rem;
}

.title {
  width: 100%;
  line-height: 30px;
  user-select: none;
  text-align: center;
  font-size: $font-size-22;
  font-weight: $font-weight-medium;
}

.simple {
  background-color: $color-white--100;
  color: $color-black--100;

  > i {
    color: $color-surrogate--100;
  }
}

.highlighted {
  background-color: $color-surrogate--100;
  color: $color-white--100;

  > i {
    color: $color-white--100;
  }
}

.badge {
  position: absolute;
  transform: translateY(-6rem);
  left: 14rem;
  z-index: $index-teaser-tile-badge;
}
