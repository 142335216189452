@import '../../styles/index';

.icon {
  svg {
    transition: color $duration-default;
  }
}

.icon-active {
  svg {
    color: $color-surrogate--20;
  }
}

.icon-inactive {
  svg {
    color: $color-surrogate--100;
  }
}
