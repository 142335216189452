@import '../../styles/index';

.headline {
  display: block;
  font-size: 1em;
  hyphens: manual;
  line-height: 1.25;
  margin-block: 0;
}

.light,
.white {
  color: $color-white--100;
}

.dark,
.black {
  color: $color-black--100;
}

.yellow {
  color: $color-yellow--100;
}

.green {
  color: $color-green--100;
}

.turquoise {
  color: $color-turquoise--100;
}

.blue {
  color: $color-blue--100;
}

.orange {
  color: $color-orange--100;
}

.purple {
  color: $color-violet--100;
}

.red {
  color: $color-red--100;
}

.surrogate {
  color: $color-surrogate--100;
}

.gradient:not(.sub-text).headline {
  &,
  & > * {
    @extend %text-linear-gradient;
  }
}

.no-align {
  text-align: inherit;
  & > .sub-text {
    text-align: inherit;
  }
}

.left {
  text-align: left;

  & > .sub-text {
    margin-right: auto;
    text-align: left;
  }
}

.center,
.center > .sub-text {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.right {
  margin-left: auto;
  margin-right: 0;
  text-align: right;

  & > .sub-text {
    margin-left: auto;
    text-align: right;
  }
}

.light-font-weight {
  font-weight: $font-weight-light;
}

.sub-text {
  color: $color-black--60;
  display: block;
  font-size: $font-size-20;
  font-weight: $font-weight-light;
  line-height: 1.4;
  margin-top: 1rem;

  @include media-from(sm) {
    font-size: $font-size-22;
  }

  &.spacing-large {
    margin-top: 1.5rem;
  }

  &.light,
  &.white {
    color: $color-white--100;
  }

  a {
    color: $color-surrogate--100;
    position: relative;
    top: 0;
    transform: scale3d(0, 0, 0);
    transition: top $duration-short ease-in-out;

    &::after {
      background: $gradient-enercity;
      border-radius: 5px;
      bottom: -2px;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform $duration-default ease-in-out;
      width: 100%;
    }

    &:hover,
    &:focus {
      cursor: pointer;
      top: -2px;

      &::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
}

.sub-text-center {
  max-width: unset;

  > p {
    margin: 1rem 0 0 0;
  }

  @include media-from(xs) {
    font-size: $font-size-22;
    line-height: 2.25rem;
  }
}

.level1 {
  .headline {
    font-size: $font-size-30;
    font-weight: $font-weight-bold;

    @include media-from(md) {
      font-size: $font-size-48;
    }
  }

  .sub-text {
    @include media-from(md) {
      font-size: $font-size-24;
    }
  }
}

.level2 {
  .headline {
    font-size: $font-size-30;
    font-weight: $font-weight-medium;

    @include media-from(xs) {
      font-size: $font-size-44;
    }
  }
}

.level3 {
  .headline {
    font-size: $font-size-24;
    font-weight: $font-weight-medium;
    line-height: 1.25;

    @include media-from(sm) {
      font-size: $font-size-30;
    }
  }
}

.level4 {
  .headline {
    font-size: $font-size-22;
    font-weight: $font-weight-medium;

    @include media-from(sm) {
      font-size: $font-size-24;
    }
  }

  .sub-text {
    font-size: $font-size-16;
    line-height: 1.5;

    @include media-from(sm) {
      font-size: $font-size-20;
      line-height: 1.4;
    }
  }
}

.wrapper-max-width {
  @include media-from(sm) {
    width: 70%;
  }
}

.badge {
  align-items: center;
  border: 1px solid rgba($color-black--100, 0.05);
  border-radius: 0.5rem;
  display: flex;
  gap: 0.375rem;
  height: 2.5rem;
  justify-content: center;
  margin-bottom: 1.5rem;
  padding: 0.25rem 1rem;
  position: relative;
  width: fit-content;

  &.light,
  &.white {
    background-color: rgba($color-white--100, 0.1);
    border-color: rgba($color-white--100, 0.05);
  }

  &.dark,
  &.black {
    background-color: rgba($color-black--100, 0.1);
    border-color: rgba($color-black--100, 0.05);
  }

  &.yellow {
    background-color: rgba($color-yellow--100, 0.1);
    border-color: rgba($color-yellow--100, 0.05);
  }

  &.green {
    background-color: rgba($color-green--100, 0.1);
    border-color: rgba($color-green--100, 0.05);
  }

  &.turquoise {
    background-color: rgba($color-turquoise--100, 0.1);
    border-color: rgba($color-turquoise--100, 0.05);
  }

  &.blue {
    background-color: rgba($color-blue--100, 0.1);
    border-color: rgba($color-blue--100, 0.05);
  }

  &.orange {
    background-color: rgba($color-orange--100, 0.1);
    border-color: rgba($color-orange--100, 0.05);
  }

  &.purple {
    background-color: rgba($color-violet--100, 0.1);
    border-color: rgba($color-violet--100, 0.05);
  }

  &.red {
    background-color: rgba($color-red--100, 0.1);
    border-color: rgba($color-red--100, 0.05);
  }

  &.surrogate {
    background-color: rgba($color-surrogate--100, 0.1);
    border-color: rgba($color-surrogate--100, 0.05);
  }

  &.gradient {
    background-color: rgba($color-surrogate--100, 0.1);
    border-color: rgba($color-surrogate--100, 0.05);
    color: $color-surrogate--100;
  }
}
