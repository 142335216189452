@import '../../styles/index';

.default {
  color: $color-surrogate--60;
  background-color: #fff;
  font-size: $font-size-14;
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  transition: color $duration-default;

  &.invalid {
    color: $color-red--100;
    background-color: transparent;
  }
}

.on-gradient {
  background-color: transparent;
  color: $color-white--100;
}
